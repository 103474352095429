import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";
import axios from "axios";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
   
  
const ParticipationForm = () => {
  const navigate = useNavigate()
  const userEmail: any = jwt_decode(localStorage.token);
  
  const formik = useFormik({
    initialValues: {
      user: userEmail.username,
      code: '',
    },
    onSubmit: async (values) => {
      try {
        await axios
        .post("https://api.dsp-archiwebo21a-hm-ah.fr/api/participation/post", values, {
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json"
          }
        })
        .then(() => {
          toast.success("Merci de votre participation !")
          navigate("/history") // Rediriger vers la page de configuration
        });
      } catch (error) {
        // toast.error("Oups! le code n'existe pas ou il est déjà utilisé !")
        console.error(error)
        navigate("/history") // Rediriger vers la page de configuration
        // window.location.reload();
      }
    }
  })
  return (
    <>
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 3000,
        },
      }}
    />
    <form onSubmit={formik.handleSubmit}>
      <Card className="w-96 m-auto bg-thewhite bg-opacity-80">
        <CardBody className="flex items-center justify-center flex-col gap-4">
        <Typography variant="h3" color="black">
            Participer Maintenant !
        </Typography>
            <label htmlFor="code" className="font-bold text-theblack">Enter your code here</label>
            <input {...formik.getFieldProps('code')} type="text" name="code" className="rounded-lg text-theblack bg-thegray bg-opacity-80" placeholder="0123456789"/>
        </CardBody>
        <CardFooter className="pt-0 m-auto">
          <Button type="submit" className="bg-thegreen hover:bg-opacity-80 hover:shadow-full duration-150">
            Participer
          </Button>
        </CardFooter>
      </Card>
    </form>
    </>
  );
}

export default ParticipationForm;