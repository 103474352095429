import { Link } from 'react-router-dom'
import SignInForm from './forms/SignInForm'
import { FcGoogle } from 'react-icons/fc'

const SignInComponent = () => {
  return (
    <section className="relative z-10 overflow-hidden pt-10 pb-10 md:pb-10 lg:pt-10 lg:pb-10">
        <div className="container">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[500px] rounded-md bg-[#d1d5db] bg-opacity-60 py-10 px-6 dark:bg-dark sm:p-[60px]">
                <h3 className="mb-20 text-center text-2xl font-bold text-theblack  sm:text-3xl">
                  Connectez-vous à votre compte
                </h3>
               
               <SignInForm />
                
                <div className="mb-8 flex items-center justify-center">
                  <span className="hidden h-[1px] w-full max-w-[70px] bg-body-color sm:block"></span>
                  <p className="w-full px-5 text-center text-xl font-bold text-body-color">
                    Ou !
                  </p>
                  <span className="hidden h-[1px] w-full max-w-[70px] bg-body-color sm:block"></span>
                </div>
                <button disabled className="mb-6 flex w-full items-center justify-center rounded-md bg-thewhite p-3 text-theblack font-medium text-body-color transition duration-300 shadow-one hover:bg-opacity-80">
                  <span className="mr-3">
                    <FcGoogle className="h-8 w-8"/>
                  </span>
                  Sign in with Google
                </button>
                <button disabled className="mb-6 flex w-full items-center justify-center rounded-md bg-[#1e3a8a] p-3 text-thewhite font-medium text-body-color transition duration-300 shadow-one hover:bg-opacity-80">
                  <span className="mr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8"
                      fill="currentColor"
                      viewBox="0 0 24 24">
                      <path
                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </span>
                  Sign in with Facebook
                </button>
                <p className="text-center text-base font-medium text-body-color">
                  Vous n'avez toujours pas de compte ?<br/>
                  <Link to="/signup" className="text-[blue] ml-2 hover:underline">
                  Inscrivez-vous ici !
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 z-[-1]">
          <svg
            width="1440"
            height="969"
            viewBox="0 0 1440 969"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_95:1005"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="1440"
              height="969"
            >
              <rect width="1440" height="969" fill="#090E34" />
            </mask>
            <g mask="url(#mask0_95:1005)">
              <path
                opacity="0.1"
                d="M1086.96 297.978L632.959 554.978L935.625 535.926L1086.96 297.978Z"
                fill="url(#paint0_linear_95:1005)"
              />
              <path
                opacity="0.1"
                d="M1324.5 755.5L1450 687V886.5L1324.5 967.5L-10 288L1324.5 755.5Z"
                fill="url(#paint1_linear_95:1005)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_95:1005"
                x1="1178.4"
                y1="151.853"
                x2="780.959"
                y2="453.581"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00723b" />
                <stop offset="1" stopColor="#00723b" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_95:1005"
                x1="160.5"
                y1="220"
                x2="1099.45"
                y2="1192.04"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00723b" />
                <stop offset="1" stopColor="#00723b" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </section>
  )
}

export default SignInComponent