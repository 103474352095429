import { Helmet } from "react-helmet";
import Accordion from "../../components/faq/Accordion";
import { Faq } from "../../types/models/FaqModel";
import { useEffect, useState } from "react";
import axios from "axios";
import parse from 'html-react-parser';



const Faqs = () => {
    const [faqs, setFaqs] = useState<Faq[]>([]);
    useEffect(() => {
    axios.get('https://api.dsp-archiwebo21a-hm-ah.fr/api/faqs', {
        headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
        }
    })
    .then((response) => {
        setFaqs(response.data);
    })
    }, [])
    if (!faqs) return null
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Consultez les réponses aux questions fréquemment posées sur les jeux concours passionnants de Thé Tip Top. Trouvez des solutions, des astuces et des informations utiles pour maximiser votre expérience de participation et de gains."/>
          <title>FAQ - Thé Tip Top</title>
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <div className="wrapper mt-10 mb-10">
            <h1 className="text-center text-5xl font-semibold mb-10">FAQ</h1>

            {/* main content */}

            <div className="max-w-[800px] w-full  mx-auto flex flex-col gap-4">
                {faqs.map((item) => {
                    const { question, answer } = item;
                    return (
                        <div className="bg-darkcolor p-5 rounded-md" key={item.id}>
                            <Accordion id={item.id} question={question} answer={parse(answer)}></Accordion>
                        </div>
                    );
                })}
            </div>
        </div>
        </>
    )
  }
  
  export default Faqs