import axios from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserModel } from '../../types/models/UserModel'
import jwt_decode from "jwt-decode";
import { isEmpty } from 'lodash'
import { log } from 'console'

const defaultInitialValues = {
    name: '',
    forename:'',
    email: '',
    phone: '',
    sexe: '',
    dob: '',
}
const EditProfileForm = () => {
    let initialValues = defaultInitialValues;
    const userEmail: any = jwt_decode(localStorage.token);
    console.log(userEmail);
    
    const [user, setUser] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await axios.get(`https://api.dsp-archiwebo21a-hm-ah.fr/api/users/${userEmail.id}`)
            setUser(response.data)
            } catch (error) {
            console.error("Error fetching data:", error)
            }
        }
        fetchData()
    }, [])
    if (user) {
       initialValues = user; 
    }
    const enableReinitialize = !isEmpty(user);
    const formik = useFormik<UserModel>({
        initialValues: initialValues,
        enableReinitialize: enableReinitialize,
        onSubmit: async (values) => {
            try {
                await axios.put(`https://api.dsp-archiwebo21a-hm-ah.fr/api/users/${userEmail.id}`, values,{
                    headers:{
                        Authorization : `Bearer ${localStorage.getItem('token')}`
                    }
                })
                console.log("Data updated successfully")
                window.location.reload();
            } catch (error: any) {
                console.error("Error updating data:", error.response.data)
            }
        }
    })
    const formattedDate = formik.values.dob.split('T')[0];
    return (
        <form onSubmit={formik.handleSubmit} className='m-10'>
            <div className="mb-8">
                <label htmlFor="email" className="mb-3 block text-sm font-medium text-dark ">
                    {" "}
                    Email{" "}
                </label>
                <p {...formik.getFieldProps('email')}>{userEmail.username}</p>
            </div>
            <div className="mb-8">
                <label htmlFor="forename" className="mb-3 block text-sm font-medium text-dark ">
                    {" "}
                    First Name{" "}
                </label>
                <input {...formik.getFieldProps('forename')} placeholder="Enter your first name"
                    className="w-full rounded-md border py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            </div>
            <div className="mb-8">
                <label htmlFor="name" className="mb-3 block text-sm font-medium text-dark ">
                    {" "}
                    Last Name{" "}
                </label>
                <input {...formik.getFieldProps('name')} placeholder="Enter your last name"
                    className="w-full rounded-md border py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            </div>
            <div className="mb-8">
                <label htmlFor="dob" className="mb-3 block text-sm font-medium text-dark ">
                    {" "}
                    Date of birth{" "}
                </label>
                <input {...formik.getFieldProps('dob')} value={formattedDate} type="date"  placeholder="Enter your date of birth"
                    className="w-full rounded-md border py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            </div>
            <div className="mb-8">
                <label htmlFor="sexe" className="mb-3 block text-sm font-medium text-dark ">
                    {" "}
                    Sexe{" "}
                </label>
                <select
                    {...formik.getFieldProps('sexe')}
                    className="w-full rounded-md border py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent"
                >
                    <option value="">Select your sexe</option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                </select>
            </div>
            <div className="mb-8">
                <label htmlFor="phone" className="mb-3 block text-sm font-medium text-dark ">
                    {" "}
                    Phone Number{" "}
                </label>
                <input {...formik.getFieldProps('phone')} placeholder="Enter your Phone Number"
                    className="w-full rounded-md border py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            </div>
            <div className="mb-6 flex">
                <button  type='submit'
                    className="flex items-center justify-center rounded-md bg-thegreen py-4 px-9 text-base font-medium text-thewhite transition duration-300 ease-in-out hover:bg-opacity-80 mx-[2px]">
                    Save
                </button>
                <Link to={'/history'} className="flex items-center justify-center bg-thebluegray rounded-md py-4 px-9 text-base font-medium text-thewhite transition duration-300 ease-in-out hover:bg-opacity-80 mx-[2px]">Cancel</Link>
            </div>
        </form>
    )
}

export default EditProfileForm