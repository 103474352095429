import { testimonial } from "../../../api/Data";
import SingleTestimonial from "./SingleItem";

const Testimonial = () => {
    return (
      <section className="relative z-10 bg-thegreen bg-opacity-10 py-10">
        <div className="container"> 
          <h2 className="font-bold break-normal py-5 text-2xl md:text-4xl m-auto text-center">Ce que disent les gens</h2>
          <hr className="w-[240px] m-auto border-2 border-thegold mb-5"/> 
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-1 lg:grid-cols-3 py-10">
            {testimonial.map((testimonial) => (
              <SingleTestimonial key={testimonial.id} testimonial={testimonial} />
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default Testimonial;