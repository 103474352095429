import { useFormik } from "formik";
import axios from "axios"

import { NewsletterModel } from "../../types/models/NewslatterModel";
import toast, { Toaster } from 'react-hot-toast';

const Newsletter = () => {
  const formik = useFormik<NewsletterModel>({
    initialValues: {
    email: '',
    status: true,
    },
    onSubmit: (values) => {
      axios
      .post("https://api.dsp-archiwebo21a-hm-ah.fr/api/newsletters", values)
      .then(() => {
        toast.success("Merci, vous êtes bien inscrit à notre newsletter !")
        window.location.reload();
      })
      .catch(error => {
        toast.success("Oups, merci de vérifier les informations saisi !")
        console.error("An error occurred while subscribing:", error)
      })
    }
  })
  return (
    <>
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 3000,
        },
      }}
    />
    <div className="w-full font-sans bg-thegray p-4 md:p-10 text-center">
      <h2 className="font-bold break-normal text-2xl md:text-4xl">Restez à l'écoute !</h2>
      <hr className="w-[240px] m-auto border-2 my-3 border-thegold "/>
      <h3 className="font-bold break-normal font-normal text-theblack text-base md:text-xl">Inscrivez-vous à notre newsletter et recevez nos actualités</h3>
      <div className="w-full text-center pt-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="max-w-xl mx-auto p-1 pr-0 flex flex-wrap items-center">
            <input type="email" {...formik.getFieldProps('email')} placeholder="youremail@example.com" className="flex-1 appearance-none rounded-lg shadow p-2 m-2 text-theblack focus:outline-none"/>
            <input type="text" {...formik.getFieldProps('status')} hidden/>
            <button type="submit" className="flex bg-thegreen text-thewhite font-semibold uppercase py-2 rounded-lg shadow hover:bg-opacity-80 hover:shadow px-10 m-auto duration-150">Subscribe</button>
          </div>
        </form>
      </div>
    </div>
    </>
  )
}

export default Newsletter