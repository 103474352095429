import { Helmet } from "react-helmet"
import SignUpComponent from "../../components/authentication/SignUp"

const SignUp = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Rejoignez la communauté de Thé Tip Top en vous inscrivant dès maintenant. Profitez d'une expérience de jeux concours inégalée et soyez prêt à gagner de cadeaux exceptionnelles !"/>
        <title>Inscription - Thé Tip Top</title>
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <SignUpComponent/>
    </>
  )
}

export default SignUp