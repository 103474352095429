import contest from '../../assets/img/contest.jpg'
import ParticipationForm from "../../components/contest/ParticpationForm"
import { Helmet } from "react-helmet"

const Contest = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Participez aux jeux concours passionnants de Thé Tip Top. Gagnez des cadeaux magnifiques et augmentez vos chances pour emporter le grand lot."/>
          <title>Jeu concours - Thé Tip Top</title>
          <meta name="robots" content="index, follow"/>
        </Helmet>
        {/* height: '700px', overflow: "hidden", alignSelf: 'center', justifySelf: 'center', justifyContent: 'center', display:"flex", flexDirection: "column", backgroundSize: 'cover' */}
        <div className="h-[500px] md:h-[700px] overflow-hidden self-center justify-center flex flex-col bg-cover" style={{backgroundImage: `url(${contest})`}}>
          <ParticipationForm />
        </div>
      </>
    )
  }
  
  export default Contest