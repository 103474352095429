import {
  Card,
  CardBody,
  Input,
  Button,
  Typography,
} from "@material-tailwind/react";
import {
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
 


const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    onSubmit: (values) => {
      axios
        .post("https://api.dsp-archiwebo21a-hm-ah.fr/api/contacts", values)
        .then(() => {
          toast.success("Merci, votre message à bien été envoyé !")
          window.location.reload();
        })
        .catch((error) => {
          toast.error("Désolé, merci de vérifier les informations saisi !");
          console.error(error);
        })
    }
  })
 
  return (
    <>
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 3000,
        },
      }}
    />
    <Card className="w-full ">
      <CardBody>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <div >
            <Typography
              variant="small"
              color="blue-gray"
              className="mt-4 font-medium"
            >
              Full name
            </Typography>
            <Input
              {...formik.getFieldProps('name')}
              type="text"
              required
            />
            <Typography
              variant="small"
              color="blue-gray"
              className="mt-4 font-medium"
            >
              Email
            </Typography>
            <Input
              {...formik.getFieldProps('email')}
              type="email"
              required
            />
            <Typography
              variant="small"
              color="blue-gray"
              className="mt-4 font-medium"
            >
              Phone number
            </Typography>
            <Input
              {...formik.getFieldProps('phone')}
              type="text"
            />
            <Typography
              variant="small"
              color="blue-gray"
              className="mt-4 font-medium"
            >
              Message
            </Typography>
            <textarea
              {...formik.getFieldProps('message')}
              required
              className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            ></textarea>
          </div>
          <Button className="bg-thegreen" type="submit" size="lg">Send message</Button>
          <Typography
            variant="small"
            color="gray"
            className="mt-2 flex items-center justify-center gap-2 font-normal opacity-60"
          >
            <LockClosedIcon className="-mt-0.5 h-4 w-4" /> Messages sent are secured !
          </Typography>
        </form>
      </CardBody>
    </Card>
    </>
  );
}

export default ContactForm;