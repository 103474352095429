import React, { useState } from "react";
import {
  Navbar,
  Typography,
  Button,
  IconButton,
  Collapse,
} from "@material-tailwind/react";
import { Link, NavLink, useLocation } from "react-router-dom";
import ProfileMenu from "./ProfilMenu";
import logo from "../../../assets/img/LogoFinal-07.png"
import MobileProfileMenu from "./MobileProfileMenu";

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Contact', href: '/contact' },
    { name: 'Blog', href: '/blog' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Contest', href: '/contest' },
]

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}
 
const NavbarComponent = () => {
  const [openNav, setOpenNav] = React.useState(false);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
        {navigation.map((item) => (
            <Typography
                key={item.name}
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
            {/* <NavLink to="#" className="flex items-center">
            Pages
            </NavLink> */}
                <NavLink
                key={item.name}
                to={item.href}
                className={classNames(
                    item.href === activeItem ? 'bg-thegold text-thegreen font-bold' : 'text-thewhite hover:bg-thegold hover:text-thegreen', 'items-center p-2 my-1 rounded'
                    
                )}
                onClick={() => setActiveItem(item.href)}
                >
                {item.name}
                </NavLink>
            </Typography>
        ))}
    </ul>
  );
 
  return (
    <Navbar className="top-0 z-10 h-max max-w-full rounded-none border-none py-2 px-4 bg-thegreen">
      <div className="container py-2 flex items-center justify-between text-blue-gray-900">
        <Link
          to="/"
          className="flex text-3xl text-thewhite font-medium md:mb-0"
        >
          <img width="120"  src={logo} alt="Logo - thé tip top" />
        </Link>
        <div className="hidden lg:block">{navList}</div>
        <div className="hidden lg:inline-block">
            {localStorage.getItem('token')
                ? <ProfileMenu /> 
                : <Link
                to="/signin"
                >
                    <Button size="sm" className="mb-2 bg-thegold text-thegreen">
                        <span>Sign In</span>
                    </Button>
                </Link>
            }
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="white"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="white"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <div className="container mx-auto">
          {navList}
          {localStorage.getItem('token') 
            ? <MobileProfileMenu /> 
            : <Link
              to="/signin"
              >
                <Button size="sm" className="mb-2 bg-thegold text-thegreen">
                    <span>Sign In</span>
                </Button>
              </Link>
          }
        </div>
      </Collapse>
    </Navbar>
  );
}

export default NavbarComponent;