import { Helmet } from "react-helmet"
import SignInComponent from "../../components/authentication/SignIn"

const SignIn = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Connectez-vous à votre compte Thé Tip Top pour participez aux jeux concours exceptionnels. Commencez à jouer, accumulez des points et tentez de remporter des récompenses fantastiques."/>
        <title>Connexion - Thé Tip Top</title>
        <meta name="robots" content="index, follow"/>
      </Helmet>
      <SignInComponent/>
    </>
  )
}

export default SignIn