import axios from 'axios'
import { error } from 'console';
import { useFormik } from 'formik'
import { redirect } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

const SignInForm = () => {
  // const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email:"",
      password:""
    },
    onSubmit: async (values, {setSubmitting})=>{
      try {
        await axios
        .post("https://api.dsp-archiwebo21a-hm-ah.fr/api/auth", values, {
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem('token', response.data.token)
          toast.success("Vous êtes bien connecté !")
          redirect('/contest')
          window.location.reload();
        }).catch(() => {
          toast.error('Identifiant ou mot de passe incorrecte');
        });
      } catch (error) {
        setSubmitting(false)
      }
    }
  })
  return (
    <>
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 3000,
        },
      }}
    />
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-8">
        <label htmlFor="email" className="mb-3 block text-sm font-medium text-dark ">
        Votre email
        </label>
        <input {...formik.getFieldProps('email')} type="email" placeholder="Entrer votre Email"
          className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-signUp" />
      </div>
      <div className="mb-8">
        <label htmlFor="password" className="mb-3 block text-sm font-medium text-dark ">
        Votre mot de passe
        </label>
        <input {...formik.getFieldProps('password')} type="password" placeholder="Tapez votre mot de passe"
          className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-signUp" />
      </div>
      <div className="mb-8 flex flex-col justify-between sm:flex-row sm:items-center">
        <div>
          <a className="text-sm font-medium text-primary hover:underline">
            Mot de passe oublié?
          </a>
        </div>
      </div>
      <div className="mb-6">
        <button disabled={formik.isSubmitting} type='submit'
          className="flex w-full items-center justify-center rounded-md bg-thegreen py-4 px-9 text-base font-medium text-thewhite transition duration-300 ease-in-out hover:bg-opacity-80 hover:shadow-signUp">
          Se connecter
        </button>
      </div>
    </form>
    </>
  )
}

export default SignInForm