import { Navigate  } from "react-router-dom";
import HistoryTable from "../../../components/dashboard/HistoryTable";
import tea7 from '../../../assets/img/tea/tea7.jpg'
import { Helmet } from "react-helmet";

const History = () => {
  // Check if a token exists in local storage
  const token = localStorage.getItem('token');

  // If there is no token, return a Redirect component to the signin page
  if (!token) {
    return <Navigate to={'/signin'}/>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Découvrez l'univers du thé bio et handmade avec Thé Tip Top. Participez, gagnez et profitez d'une expérience de divertissement unique. Rejoignez-nous pour des opportunités passionnantes !"/>
        <title>Historique - Thé Tip Top</title>
      </Helmet>
      <div style={{backgroundImage: `url(${tea7})`, height: 'auto', overflow: "hidden", alignSelf: 'center', justifySelf: 'center', justifyContent: 'center', display:"flex", flexDirection: "column", backgroundSize: 'cover'}}>
        <HistoryTable/>
      </div>
    </>
  );
};

export default History;
