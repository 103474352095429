import { Button, Carousel, Typography } from "@material-tailwind/react";

import { useEffect, useState } from "react";
import { BlogModel } from "../../types/models/BlogModel";
import axios from "axios";
import { Toaster } from 'react-hot-toast';
import { Link } from "react-router-dom";

 
const CarouselComponent = () => {
  const [articles, setArticles] = useState<BlogModel[]>([]);
  useEffect(() => {
      axios.get('https://api.dsp-archiwebo21a-hm-ah.fr/api/ads', {
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json"
        }
      })
      .then((response) => {
        setArticles(response.data);
      }).catch((error) => {
        console.error(error);
      })    
  }, [])
  if (!articles) return null
  return (
    <>
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 3000,
        },
      }}
    />

    <Carousel
      className="h-[500px]"
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      {articles.map((item) => {
        return (
          <div key={item.id} className="relative h-[500px]">
            <img
              src={item.picture}
              alt={item.title}
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
              <div className="w-3/4 text-center md:w-2/4">
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80"
                >
                  {item.description}
                </Typography>
                <div className="flex justify-center gap-2">
                  <Link to={'/contest'}>
                    <Button size="lg" className="bg-thegreen mb-10">
                      Participer
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>

    </>
  );
}

export default CarouselComponent;