import * as Yup from "yup";

const phoneRegEx = /^(0)[0-9]{9}$/
const passwordRegEx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export const userSchema = Yup.object({
    name: Yup.string()
      .required("Le nom est obligatoire !")
      .max(20, "Votre nom doit contenir moins de 20 caractères")
      .min(3, "Votre nom ne doit pas contenir moins de 3 caractères")
      .required("Votre nom est requis"),
    forename: Yup.string()
      .required("Le prénom est obligatoire !")
      .max(20, "Votre Prénom doit contenir moins de 20 caractères")
      .min(3, "Votre Prénom ne doit pas contenir moins de 3 caractères")
      .required("Votre Prénom est requis"),
    email: Yup.string().email("l'adresse email n'est pas valide !").required("L'émail est obligatoir !"),
    phone: Yup.string().matches(phoneRegEx, 'Le numéro de téléphone n\'est pas valide'),
    password: Yup.string().matches(passwordRegEx, "Veuillez choisir un mot de passe d'au moins 8 caractères, comprenant au moins un symbole, une lettre majuscule, une lettre minuscule et un chiffre.").required('Le mot de passe est obligatoire !')
  });