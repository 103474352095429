import {
    MapPinIcon,
    EnvelopeIcon,
    PhoneIcon
  } from "@heroicons/react/24/outline";
import ContactForm from './ContactForm';
import logo from "../../assets/img/LogoFinal-08.png";

const ContactComponent = () => {
  return (
    <>
    <div className="container px-1 mx-auto xl:px-5">
      
      <div className="grid bg-thegray rounded-xl p-5 m-5 grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-1 lg:grid-cols-3">
        <div className="my-0 col-1">
          <img src={logo} alt="Contacter Thé tip Top si vous avez des questions"
          />
          <p className="max-w-sm mt-5">
            Nous sommes ravis de vous accueillir sur notre site web. Si vous avez des questions, des commentaires ou si vous souhaitez simplement entrer en contact avec nous, veuillez remplir le formulaire. Nous vous répondrons dans les plus brefs délais.
          </p>

          <div className="mt-5">
            <div className="flex items-center mt-2 space-x-2 text-dark-600 dark:text-thegray-400">
              <MapPinIcon className="w-4 h-4" />
              <span>18 rue Léon Frot, 75011 Paris</span>
            </div>
              <div className="flex items-center mt-2 space-x-2 text-dark-600 dark:text-thegray-400">
                <EnvelopeIcon className="w-4 h-4" />
                <a>
                  Email : contact@thetiptop.fr
                </a>
              </div>
              <div className="flex items-center mt-2 space-x-2 text-dark-600 dark:text-thegray-400">
                <PhoneIcon className="w-4 h-4" />
                <a>Téléphone : 01 01 01 01 01</a>
              </div>
          </div>
        </div>
        <div className="my-0 col-span-2 sm-col-1">
          <ContactForm />
        </div>
      </div>
    </div>
    </>
  )
}

export default ContactComponent;