import Article from "../../components/blog/Article"
import HeroSection from "../../components/shared/HeroSection";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import { BlogModel } from "../../types/models/BlogModel";

const Blog = () => {
  const [articles, setArticles] = useState<BlogModel[]>([]);
  useEffect(() => {
    axios.get('https://api.dsp-archiwebo21a-hm-ah.fr/api/blogs', {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
      }
    })
    .then((response) => {
      setArticles(response.data);
    })
  }, [])
  if (!articles) return null
  
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Restez à jour avec les dernières actualités et informations sur les jeux concours chez Thé Tip Top. Explorez nos articles divertissants et instructifs sur le monde du thé bio et handmade."/>
      <title>Blog - Thé Tip Top</title>
      <meta name="robots" content="index, follow"/>
    </Helmet>
    <HeroSection title={'Blog page'}/>
    
    <main className="mx-7 lg:mx-6 mt-10 mb-10 flex-grow">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-wrap -mx-2">
            {articles.map((item) => {
                return (
                  <div className="w-full sm:w-1/2 md:w-1/3 self-stretch p-2 mb-2">
                    <Article id={item.id} title={item.title} image={item.picture} description={item.description}/>
                  </div>
                );
            })}
        </div>

        {/* <div className="mt-3 flow-root">
            <a href="javascript:void(0)" className="float-left bg-thewhite font-semibold py-2 px-4 border rounded shadow-md text-slate-800 cursor-default text-opacity-50">Previous</a>
            <a href="/page/2/" className="float-right bg-thewhite font-semibold py-2 px-4 border rounded shadow-md text-slate-800 cursor-pointer hover:bg-slate-100">Next</a>
        </div> */}

      </div>
    </main>
    </>
  )
}

export default Blog