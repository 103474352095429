import { useFormik } from "formik";
import { Link } from 'react-router-dom';
import { UserModel } from '../../../types/models/UserModel';
import axios from 'axios';
import { Checkbox } from "@material-tailwind/react";
import { useState } from "react";
import { userSchema } from "../../../types/schemas/UserSchema";

const SignUpForm = () => {
    const [isChecked, setIsChecked] = useState(false);
    const formik = useFormik<UserModel>({
        initialValues: {
        name: '',
        forename:'',
        email: '',
        phone: '',
        password: '',
        sexe: '',
        dob: '',
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
        axios
        .post("http://api.dsp-archiwebo21a-hm-ah.fr/api/users", values)
        .then(response => {
        console.log("User created successfully:", response.data)
        })
        .catch(error => {
        console.error("An error occurred while creating the user:", error)
        })
    }

    })
    return (
    <form onSubmit={formik.handleSubmit}>
        <div className="mb-8">
            <label htmlFor="forename" className="mb-3 block text-sm font-medium text-dark ">
                {" "}
                Prénom{" "}
            </label>
            <input {...formik.getFieldProps("forename")} placeholder="Entrez votre prénom"
                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            <p className="text-thered">
                {formik.touched.forename && formik.errors.forename
                  ? formik.errors.forename
                  : ""}
            </p>
        </div>
        <div className="mb-8">
            <label htmlFor="name" className="mb-3 block text-sm font-medium text-dark ">
                {" "}
                Nom de famille{" "}
            </label>
            <input {...formik.getFieldProps("name")} placeholder="Entrez votre nom de famille"
                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            <p className="text-thered">
                {formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ""}
            </p>
        </div>
        <div className="mb-8">
            <label htmlFor="dob" className="mb-3 block text-sm font-medium text-dark ">
                {" "}
                Date de naissance{" "}
            </label>
            <input type='date' {...formik.getFieldProps("dob")} placeholder="Entrez votre date de naissance"
                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
        </div>
        <div className="mb-8">
            <label htmlFor="sexe" className="mb-3 block text-sm font-medium text-dark ">
                {" "}
                Sexe{" "}
            </label>
            <select
                {...formik.getFieldProps('sexe')}
                className="w-full rounded-md border py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none"
            >
                <option value="">Sélectionnez votre sexe</option>
                <option value="M">M</option>
                <option value="F">F</option>
            </select>
        </div>
        <div className="mb-8">
            <label htmlFor="email" className="mb-3 block text-sm font-medium text-dark ">
                {" "}
                Email{" "}
            </label>
            <input {...formik.getFieldProps("email")} placeholder="Enter your Email"
                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            <p className="text-thered">
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""}
            </p>
        </div>
        <div className="mb-8">
            <label htmlFor="phone" className="mb-3 block text-sm font-medium text-dark ">
                {" "}
                Numéro de téléphone{" "}
            </label>
            <input {...formik.getFieldProps("phone")} placeholder="Entrez votre numéro de téléphone"
                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            <p className="text-thered">
                {formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : ""}
            </p>
        </div>
        <div className="mb-8">
            <label htmlFor="password" className="mb-3 block text-sm font-medium text-dark ">
                {" "}
                Votre mot de passe{" "}
            </label>
            <input type="password" {...formik.getFieldProps("password")} placeholder="Tapez votre mot de passe"
                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:shadow-SignUpComponentSignUpComponent" />
            <p className="text-thered">
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""}
            </p>
        </div>
        <div className="mb-8 flex">
            <label htmlFor="checkboxLabel"
                className="flex cursor-pointer select-none text-sm font-medium text-body-color">
                <div className="relative">
                    <Checkbox onChange={() => setIsChecked(!isChecked)} color="blue" defaultChecked/>
                </div>
                <span>
                    En créant un compte, vous acceptez les
                    <Link to="/terms-and-conditions" target="_blank" className="text-theblack underline hover:text-[blue]">
                    {" "}
                    Conditions Générales{" "}
                    </Link>
                    , et notre
                    <Link to="/privacy-policy" target="_blank" className="text-theblack underline hover:text-[blue]">
                    {" "}
                    politique de confidentialité{" "}
                    </Link>
                </span>
            </label>
        </div>
        <div className="mb-8 flex">
            <label htmlFor="checkboxLabel"
                className="flex cursor-pointer select-none text-sm font-medium text-body-color">
                <div className="relative">
                    <Checkbox color="blue" defaultChecked disabled/>
                </div>
                <span>
                    I don't want to receive marketing emails and feature updates.
                </span>
            </label>
        </div>
        <div className="mb-6">
            <button 
                type='submit' 
                disabled={isChecked}
                className="flex w-full items-center justify-center rounded-md bg-thegreen py-4 px-9 text-base font-medium text-thewhite transition duration-300 ease-in-out hover:bg-opacity-80 hover:shadow-SignUpComponentSignUpComponent">
                S'inscrire
            </button>
        </div>
    </form>
    )
}

export default SignUpForm