import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react'
import axios from 'axios'
import parse from 'html-react-parser';

const TermsAndConditions = () => {  
    const [termsConditions, setTermsConditions] = useState<any>();

    useEffect(() => {
        axios.get('https://api.dsp-archiwebo21a-hm-ah.fr/api/terms_conditions/1', {
        headers: {
            "Content-Type": "application/json",
            "accept": "application/json"
        }
        })
        .then((response) => {
            setTermsConditions(response.data);
        }).catch((error) => {
            console.error(error);
        })
    }, [])
    if (!termsConditions) return null
    
    return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="name" content="Consultez les mentions légales de Thé Tip Top. Informations sur la propriété intellectuelle, la protection des données personnelles, la responsabilité et plus encore."/>
      <title>Conditions Générales - Thé Tip Top</title>
      <meta name="robots" content="index, follow"/>
    </Helmet>

    <div className="container py-10">
        <Card className="w-auto m-auto py-10 bg-[#f1f5f9] bg-opacity-80">
            <CardHeader 
                variant="gradient"
                className="mb-4 grid h-28 bg-thegold bg-opacity-90 place-items-center">
                <Typography variant='h1' color='black' className="text-2xl sm:text-3xl md:text-5xl">Conditions Générales</Typography>
            </CardHeader>
            <CardBody  className="flex items-center justify-center flex-col gap-4">
                <div style={{textAlign: "justify"}} className="p-5" key={termsConditions.id}>
                    {parse(termsConditions.content)} 
                </div>
            </CardBody>
        </Card>
    </div>

    </>
  )
}

export default TermsAndConditions