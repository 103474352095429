import { useParams } from "react-router-dom"
import Detail from "../../components/blog/Detail"
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import { BlogModel } from "../../types/models/BlogModel";
import parse from 'html-react-parser';


const DetailBlog = () => {
  const {id} = useParams();
  
  const [articles, setArticles] = useState<BlogModel | null>(null);

  useEffect(() => {
    axios.get(`https://api.dsp-archiwebo21a-hm-ah.fr/api/blogs/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
      }
    })
    .then((response) => {
      setArticles(response.data);
    }).catch((error) => {
      console.error(error);
    })
  }, [id])
  if (!articles) return null
  return (
  <>
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="description" content="Détail blog"/>
    <title>Détail blog</title>
    <meta name="robots" content="index, follow"/>
  </Helmet>
  <main className="mx-7 lg:mx-6 mt-10 flex-grow">
          <article className="max-w-5xl mx-auto" key={articles.id}>
            <Detail title={articles.title} image={articles.picture} description={parse(articles.description)}/>
          </article>
  </main>
  </>

)
}

export default DetailBlog