import { Cog6ToothIcon, PowerIcon, ClockIcon } from "@heroicons/react/24/outline";
import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from "@material-tailwind/react";
import React from "react";
import Gravatar from "react-gravatar";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const profileMenuItems = [
  {
    label: "Edit Profile",
    icon: Cog6ToothIcon,
    page: '/profile',
  },
  {
    label: "History",
    icon: ClockIcon,
    page: '/history',
  },
  {
    label: "Sign Out",
    icon: PowerIcon,
    page: '/',
  },
];

const ProfileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate()
  const closeMenu = () => setIsMenuOpen(false);
  const signOut = (page: string) => {
    localStorage.removeItem('token');
    navigate(page);
    closeMenu();
    window.location.reload();
  }
  
  
  return (
    <>
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 5000,
        },
      }}
    />
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5"
        >
          <Gravatar className='rounded-full h-10 w-10' default="mp" email="hmadanihoussem98@gmail.com" />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon, page }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={isLastItem ? () => {
                signOut(page);
                toast.success("Vous êtes déconnecté !");
              } : () => navigate(page)}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-thered-500/10 focus:bg-thered-500/10 active:bg-thered-500/10"
                  : ""
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-[red]" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
    </>
  );
}

export default ProfileMenu;