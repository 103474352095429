import { Helmet } from 'react-helmet'
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react'

const About = () => {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="ThéTipTop est une société ayant pour activité la promotion de gammes de thés de très grande qualité avec des mélanges signatures de l’entreprise, des thés détox, des thés blancs, des thés légumes, infusions, etc. L’ensemble des thés sont bios et Handmades."/>
      <title>Qui somme nous ? - Thé Tip Top</title>
      <meta name="robots" content="index, follow"/>
    </Helmet>

    <div className="container py-10">
        <Card className="w-auto m-auto py-10 bg-[#f1f5f9] bg-opacity-80">
            <CardHeader 
                variant="gradient"
                className="mb-4 grid h-28 bg-thegold bg-opacity-90 place-items-center">
                <Typography variant='h1' color='black' className="text-2xl sm:text-3xl md:text-5xl">Qui Somme Nous ?</Typography>
            </CardHeader>
            <CardBody  className="flex items-center justify-center flex-col gap-4">
                <div style={{textAlign: "justify"}}>
                    <p className='m-5'>Au sein d'une petite ville nichée entre des montagnes majestueuses et des champs verdoyants,
                        vivaient deux inséparables amis, Max et Alex. Leur amitié était semblable à une étoile brillante
                        dans le ciel de leur communauté, apportant joie et énergie à tous ceux qui croisaient leur
                        chemin.</p>
                    <p className='m-5'>Max, un esprit aventurier aux yeux pétillants, avait un amour incommensurable pour les activités
                        de plein air. Qu'il s'agisse de randonnées ardentes vers les sommets ou d'excursions en canoë
                        sur les rivières scintillantes, Max était toujours partant pour l'aventure. Sa créativité
                        débordante l'amenait à trouver des façons uniques d'explorer chaque recoin de leur ville natale.
                    </p>
                    <p className='m-5'>D'un autre côté, il y avait Alex, le calme contrepoids de l'amitié. Alex était un rêveur
                        profondément ancré, captivé par les mondes que l'on trouve dans les pages des livres. Sa passion
                        pour la lecture et l'écriture le transportait dans des univers imaginaires, où il trouvait un
                        refuge paisible. Son esprit réfléchi et sa sagesse en faisaient souvent le confident de Max,
                        écoutant attentivement les histoires trépidantes de ses aventures.</p>
                    <p className='m-5'>Malgré leurs différences, Max et Alex étaient complémentaires. Là où Max apportait une touche
                        d'excitation et de spontanéité à la vie d'Alex, Alex offrait à Max une source de réflexion et de
                        tranquillité. Leur amitié était comme un puzzle parfaitement assemblé, chaque pièce s'emboîtant
                        avec harmonie pour créer une image complète et épanouissante.</p>
                    <p className='m-5'>Les étés étaient leurs moments préférés. Les journées chaudes étaient remplies d'expéditions dans
                        les bois, de pique-niques au bord du lac et de nuits à contempler les étoiles. Les hivers, en
                        revanche, les trouvaient rassemblés autour d'une cheminée, partageant des récits et des rires
                        chaleureux tout en savourant des tasses de chocolat chaud.</p>
                    <p className='m-5'>Leur plus grande aventure arriva lorsque Max décida de relever le défi de gravir la plus haute
                        montagne de la région. Alex, bien qu'initialement hésitant face à cette entreprise audacieuse,
                        décida de soutenir son ami. Ils s'embarquèrent ensemble dans une ascension épique, où chaque pas
                        les rapprochait de leur objectif et renforçait leur lien déjà solide.</p>
                    <p className='m-5'>Au sommet, tandis qu'ils contemplaient le panorama à couper le souffle qui s'étendait devant eux,
                        Max et Alex réalisèrent à quel point leur amitié était précieuse. Leurs personnalités
                        contrastantes avaient créé une dynamique spéciale qui les avait enrichis mutuellement. Ils
                        étaient plus qu'amis - ils étaient une source d'inspiration l'un pour l'autre.</p>
                    <p className='m-5'>Ainsi, Max et Alex continuaient leur voyage à travers la vie, main dans la main. Leurs aventures
                        étaient à la fois des souvenirs gravés dans le temps et des promesses d'avenir, une histoire
                        vivante de deux cœurs qui se sont trouvés et qui, ensemble, ont découvert la beauté de la
                        diversité et de l'amitié sincère.</p>
                </div>
            </CardBody>
        </Card>
    </div>

    </>
  )
}

export default About