import tea1 from '../assets/img/tea/tea1.jpg'
import tea3 from '../assets/img/tea/tea3.jpg'
import tea4 from '../assets/img/tea/tea4.jpg'
import tea5 from '../assets/img/tea/tea5.jpg'

export const accordions = [
    {
        id: 1,
        question: "How to participate ?",
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 2,
        question: "How to use the code ?",
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 3,
        question: "Where to get the prise ?",
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 4,
        question: "What is the big prise ?",
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
];

export const articles = [
    {
        id: 1,
        title: "How to participate ?",
        image: tea5,
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 2,
        title: "How to use the code ?",
        image: tea1,
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 3,
        title: "Where to get the prise ?",
        image: tea3,
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 4,
        title: "What is the big prise ?",
        image: tea4,
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
];

export const testimonial = [
  {
    id: 1,
    name: "Musharof Chy",
    designation: "Founder @TailGrids",
    content:
      "Je ne savais pas que le thé pouvait être aussi bon. Votre concours m'a fait découvrir un monde de saveurs que je ne peux plus me passer.",
    image: "../assets/img/testimonials/auth-01.png",
    star: 4,
  },
  {
    id: 2,
    name: "Devid Weilium",
    designation: "Founder @UIdeck",
    content:
      "Gagner à chaque achat de 49€, c'est incroyable ! En plus, le thé est de la plus haute qualité. Je suis comblé.",
    image: "../assets/img/testimonials/auth-02.png",
    star: 3,
  },
  {
    id: 3,
    name: "Lethium Frenci",
    designation: "Founder @Lineicons",
    content:
      "Gagner des lots tout en appréciant du thé de qualité, c'est le meilleur des deux mondes. Je suis fan de cette offre !",
    image: "../assets/img/testimonials/auth-03.png",
    star: 5,
  },
];

export const TABLE_ROWS = [
  {
    code: "0008990876",
    prize: "Manager",
    date: "23/04/2023",
  },
  {
    code: "6657432319",
    prize: "Developer",
    date: "23/04/2023",
  },
  {
    code: "2008726543",
    prize: "Executive",
    date: "19/09/2023",
  },
  {
    code: "0089765435",
    prize: "Developer",
    date: "24/12/2023",
  },
  {
    code: "1442351423",
    prize: "Manager",
    date: "04/10/2023",
  },
  {
    code: "2008726543",
    prize: "Executive",
    date: "19/09/2023",
  },
  {
    code: "0089765435",
    prize: "Developer",
    date: "24/12/2023",
  },
  {
    code: "1442351423",
    prize: "Manager",
    date: "04/10/2023",
  },
];

