import { ClockIcon, Cog6ToothIcon, PowerIcon } from '@heroicons/react/24/outline';
import { Option, Select, Typography } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom';

const profileMenuItems = [
    {
      label: "Edit Profile",
      icon: Cog6ToothIcon,
      page: '/profile',
    },
    {
      label: "History",
      icon: ClockIcon,
      page: '/history',
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
      page: '/',
    },
];


const MobileProfileMenu = () => {
const navigate = useNavigate()
const signOut = (page: string) => {
    localStorage.removeItem('token');
    navigate(page);
    window.location.reload();
}
  return (
    <>
    <Select
        label="Profile"
        className='text-thewhite color-thewhite bg-thewhite'
        variant="outlined"
    >
        {profileMenuItems.map(({ label, icon, page }, key) => {
            const isLastItem = key === profileMenuItems.length - 1;
            return(
                <Option
                key={label}
                onClick={isLastItem ? () => signOut(page) : () => navigate(page)}
                className={`flex items-center gap-2 rounded ${
                    isLastItem
                      ? "hover:bg-thered-500/10 focus:bg-thered-500/10 active:bg-thered-500/10"
                      : ""
                }`}
                >
                    <Typography
                        as="span"
                        variant="small"
                        className="font-normal"
                        color={isLastItem ? "red" : "inherit"}
                    >
                        {label}
                    </Typography>
                </Option>
            );
            
        })}
    </Select>
    </>
  )
}

export default MobileProfileMenu