import { Card, CardBody, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";

interface Participation {
  user: any,
  code: any,
  prize: any,
  date: any,
}

const TABLE_HEAD = ["", "Code", "Lot gagner", "Status"];

const HistoryTable = () => {
  const [participations, setParticipations] = useState<Participation[]>([]);
  const user: any = jwt_decode(localStorage.token);
  console.log(user.name)
  useEffect(() => {
      axios.get('https://api.dsp-archiwebo21a-hm-ah.fr/api/participations', {
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json"
        }
      })
      .then((response) => {
        const nameToFind = user.name; // Replace with the name you want to search for
        const forenameToFind = user.forename; // Replace with the forename you want to search for

        // Use the find() method to find the object with the specified name and forename
        const userObject = response.data.filter((obj: any) => obj.user && obj.user.name === nameToFind && obj.user.forename === forenameToFind);
        
        setParticipations(userObject);
      }).catch((error) => {
        console.error(error);
      })    
  }, [])
  if (!participations) return null
  return (
    <div className="mx-5 my-10 md:m-20">
      <Card className="max-w-96 m-auto bg-thegray my-10 shadow">
        <CardBody className="mb-4 grid place-items-center">
          <Typography variant='h1' color='black'>Historique</Typography>
        </CardBody>
      </Card>
      <Card className="h-full w-full overflow-scroll rounded-lg">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {participations.map((item, index) => (
            <tr key={index} className="even:bg-blue-gray-50/50">
              <td className="p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                  {index+1}
                </Typography>
              </td>
              <td className="p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                  {item.code.refCode}
                </Typography>
              </td>
              <td className="p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                  {item.prize.name}
                </Typography>
              </td>
              <td className="p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                {item.code.status === "used" && ( <>Utilisé</> ) }
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
    </div>
    
  );
}

export default HistoryTable;