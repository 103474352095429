import  Helmet  from "react-helmet";
import ContactComponent from "../../components/contact/ContactComponent";
import HeroSection from "../../components/shared/HeroSection";

const Contact = () => {

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Besoin d'aide ou des questions sur notre marque Thé Tip Top ? Contactez notre équipe de support dédiée. Nous sommes là pour répondre à vos préoccupations et vous assister."/>
      <title>Contact - Thé Tip Top</title>
      <meta name="robots" content="index, follow"/>
    </Helmet>
    <HeroSection title={'Contact page'}/>

    <ContactComponent />
    </>
  );
}

export default Contact
