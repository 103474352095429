import Testimonial from "../../components/home/testimonial"
import tea7 from "../../assets/img/tea/tea7.jpg";
import CarouselComponent from "../../components/shared/CarouselComponent";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import logo from "../../assets/img/LogoFinal-07.png"
import { Typography } from "@material-tailwind/react";

const Home = () => {
  const STARTING_DATE = 1 * 1 * 1 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const expiresIn = new Date(NOW_IN_MS + STARTING_DATE);
  return (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="ThéTipTop est une société ayant pour activité la promotion de gammes de thés de très grande qualité avec des mélanges signatures de l’entreprise, des thés détox, des thés blancs, des thés légumes, infusions, etc. L’ensemble des thés sont bios et Handmades."/>
      <title>Accueil - Thé Tip Top</title>
      <meta name="robots" content="index, follow"/>
    </Helmet>
    <CarouselComponent/>

    <div className="w-full font-sans mt-5">

      <section className="py-5">
        <div className="container">
          <h2 className="font-bold break-normal text-2xl md:text-4xl m-auto text-center">Qui somme nous ?</h2>
          <hr className="w-[240px] m-auto border-2 my-3 border-thegold "/>
          <div className="-mx-4 flex flex-wrap py-5">
            <div className="w-full lg:w-1/2">
              <div
                className="wow relative"
                data-wow-delay=".15s"
              >
                <img
                className="rounded-md"
                  src={tea7}
                  alt="Savoir plus à propos de notre marque Thé Tip Top"
                />
                <div className="rounded-md absolute inset-0 grid items-center bg-black/60 object-cover">
                  <div className=" flex gap-2 m-auto">
                    <img src={logo} alt="Thé Tip Top, thé bio et handmade" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <div className="wow fadeInUp " data-wow-delay=".2s">
                <div className="mb-9">
                  <Typography variant="h3" className="mb-4 underline text-upercase">Notre Histoire</Typography>
                  <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                    Au sein d'une petite ville nichée entre des montagnes majestueuses et des champs verdoyants,
                    vivaient deux inséparables amis, Max et Alex. Leur amitié était semblable à une étoile brillante
                    dans le ciel de leur communauté, apportant joie et énergie à tous ceux qui croisaient leur chemin.
                    Max, un esprit aventurier aux yeux pétillants, avait un amour incommensurable pour les activités de
                    plein air. Qu'il s'agisse de randonnées ardentes vers les sommets ou d'excursions en canoë sur les
                    rivières scintillantes, Max était toujours partant pour l'aventure. Sa créativité débordante
                    l'amenait à trouver des façons uniques d'explorer chaque recoin de leur ville natale.
                    
                  </p>
                  <Link to={'/about'} className="float-right hover:underline text-[blue]">Lire plus ...</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial/>
    </div>
  </>
  )
}

export default Home