import Gravatar from "react-gravatar";
import { Testimonial } from "../../../types/testimonial";


const starIcon = (
  <svg width="18" height="16" viewBox="0 0 18 16" className="fill-current">
    <path d="M9.09815 0.361679L11.1054 6.06601H17.601L12.3459 9.59149L14.3532 15.2958L9.09815 11.7703L3.84309 15.2958L5.85035 9.59149L0.595291 6.06601H7.0909L9.09815 0.361679Z" />
  </svg>
);

const SingleTestimonial = ({ testimonial }: { testimonial: Testimonial }) => {
  const { star, name, image, content, designation } = testimonial;

  let ratingIcons = [];
  for (let index = 0; index < star; index++) {
    ratingIcons.push(
      <span key={index} className="text-theyellow">
        {starIcon}
      </span>
    );
  }

  return (
    <div className="w-full">
      <div
        className="wow fadeInUp rounded-2xl bg-thegold bg-opacity-20 p-8 shadow-2xl  max-h-[250px] h-[250px]  items-center"
        data-wow-delay=".1s"
      >
        <div className="flex items-center">
          <div className="relative mr-4 h-[50px] w-full max-w-[50px] overflow-hidden rounded-full">
            <Gravatar className='rounded-full' default="mp" email="admin@gmail.com"/>
          </div>
          <div className="w-full">
            <h3 className="mb-1 text-lg font-bold text-dark lg:text-base xl:text-lg">
              {name}
            </h3>
            <div className="mb-5 flex items-center space-x-1">{ratingIcons}</div>
          </div>
        </div>
        
        <p className="text-dark leading-relaxed text-body-color">
          “{content}
        </p>
        
      </div>
    </div>
  );
};

export default SingleTestimonial;
