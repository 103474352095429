import { Link } from "react-router-dom"
import parse from 'html-react-parser';

const Article = ({ id, title, image, description }:{ id: number, title: string, image: string, description: any }) => {
    return (
        
            <div className="rounded shadow-md h-full" key={id}>
                <img className="w-full m-0 rounded-t lazy" src={image} width="960" height="500" alt={title}/>
                <div className="px-6 py-5">
                    <div className="font-semibold text-lg mb-2">
                        <h4>{title}</h4>
                    </div>
                    <p className="text-slate-700 mb-1" title="Published date">21 June 2020 08:04 AM</p>
                    <p className="text-slate-800">
                        {parse(`${description.slice(0, 100) + "..."}`) }
                    </p>
                    {/* <Link className="float-right p-5 underline" to={`/blog/detail-blog/${id}`}>Lire la suite...</Link> */}
                    <Link className="float-right p-5 underline" to={`/blog/detail-blog/${id}`}>Lire la suite...</Link>
                </div>
            </div>
    )
  }
  
  export default Article