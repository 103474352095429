import tea2 from '../../assets/img/tea/tea3.jpg'

const HeroSection = ({title}: {title: string}) => {
  return (
    <div style={{backgroundImage: `url(${tea2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}} className='h-96'>
      <div className="relative inset-0 grid h-[100%] w-full items-center bg-black/75">
      <h1 className="text-5xl font-bold text-thewhite m-auto justify-self-center">{title}</h1>
      </div>
      
        {/* <Typography className="text-5xl font-bold text-thewhite m-auto justify-self-center" variant='h1' >{title}</Typography> */}
      
    </div >
  )
}

export default HeroSection