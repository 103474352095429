import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import tea3 from '../../../assets/img/tea/tea3.jpg'
import Gravatar from "react-gravatar";
import EditProfileForm from "../../../components/dashboard/EditProfileForm";
import { Helmet } from "react-helmet";

const Profile = () => {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Profile - Thé Tip Top</title>
    </Helmet>
    <div className="py-20 m-auto" style={{backgroundImage: `url(${tea3})`, backgroundSize: 'cover', display: 'flex', justifyContent: 'center'}}>
      <Card className="w-[700px] m-5">
        <CardHeader
          variant="gradient"
          color="gray"
          className="mb-4 grid h-60 place-items-center"
        >
          <Gravatar email="harakatabdellah.9@gmail.com" className='rounded-full' default="mp" width={'120px'}/>
          <Typography variant="h3" color="white">
            Edit your profile
          </Typography>
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <EditProfileForm />
        </CardBody>
      </Card>
    </div>
    </>
    
  )
}

export default Profile;