const Detail = ( {title, image, description }:{title: string, image: string, description: any }) => {
  return (
    <>
    <header className="mb-14">
      <h1 className="text-3xl text-center font-bold leading-normal text-slate-900 mt-0 mb-3">{title}</h1>
      <div className="text-center">Published on 21 June 2020 08:04 AM</div>
  

      <div className="mt-10 -mx-7 md:mx-0">
        <img className="w-full max-w-2xl mx-auto rounded-lg shadow-full" src={`${image}`} width="960" height="500"
          alt={title} />
      </div>

    </header>
    <div id="content" className="prose text-slate-800 max-w-none">
      <p>{description}</p>

    </div>
    </>
  )
}

export default Detail