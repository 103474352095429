import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Footer from './components/shared/footer/Footer';
import Home from './pages/home';
import Faq from './pages/faq';
import Contact from './pages/contact';
import Blog from './pages/blog';
import DetailBlog from './pages/blog/DetailBlog';
import ErrorPage from './pages/error';
import SignIn from './pages/authentication/SignIn';
import SignUp from './pages/authentication/SignUp';
import Contest from './pages/contest';
import History from './pages/protected/history';
import Profile from './pages/protected/profile';
import About from './pages/home/About';
import PrivacyPolicy from './pages/privacy-policy';
import NavbarComponent from './components/shared/header/NavbarComponent';
import TermsAndConditions from './pages/terms-and-conditions';

const App = () => {
  return (
    <div className='container-fluid'>
      <Router>

          <NavbarComponent/>

          <Routes>
            <Route path="/*" element={<ErrorPage />}/>
            <Route path="/" element={<Home />}/>
            <Route path="/about" element={<About />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
            <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
            <Route path="/faq" element={<Faq />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/blog" element={<Blog />}/>
            <Route path="/blog/detail-blog/:id" element={<DetailBlog />}/>
            <Route path="/contest" element={localStorage.getItem('token') ? <Contest/> : <Navigate to={'/signin'}/>}/>
            <Route path="/signin" element={localStorage.getItem('token') ? <Navigate to={'/history'}/> : <SignIn/>}/>
            <Route path="/signup" element={<SignUp />}/> 
            <Route path="/history" element={localStorage.getItem('token') ? <History/> : <Navigate to={'/signin'}/>}/> 
            <Route path="/profile" element={localStorage.getItem('token') ? <Profile/> : <Navigate to={'/signin'}/>}/> 
          </Routes>

          <Footer/> 
      </Router>
    </div>
  );
}

export default App;
